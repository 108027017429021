import CanvasFunctions from '../../lib/canvas-functions';

// @vue/component
export default {
    name: 'kk-canvas',

    props: {
        /**
         * title of <canvas> element. Shows when you hover
         */
        title: {
            type: String,
            default: 'Image',
        },
        /**
         * src url for image to be drawn
         */
        src: {
            type: String,
            default: null,
            validator: value => value === null || typeof value === 'string',
        },
    },

    data() {
        return {
            img: null,
            canvas: null,
            ctx: null,
            lastX: 0,
            lastY: 0,
            dragged: false,
            dragStart: false,
        };
    },

    watch: {
        src: {
            immediate: true,
            handler(src) {
                if (!src) {
                    return;
                }

                this.$nextTick(() => {
                    this.load(src);
                });
            },
        },
    },

    mounted() {
        window.addEventListener('resize', this.resize);
        this.canvas = this.$refs.canvas;
        this.ctx = CanvasFunctions.makeCtxWithTransformMatrix(this.$refs.canvas.getContext('2d'));
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    },

    methods: {
        load(src) {
            this.clear();
            let img = new Image();

            img.addEventListener('load', () => {
                this.img = img;
                this.resize();
                this.draw();
            });
            img.addEventListener('error', () => {
                this.clear();
            });

            img.src = src;
        },
        //
        // Events
        //
        onMouseDown(evt) {
            let canvas = this.canvas;
            let ctx = this.ctx;

            this.lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
            this.lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
            this.dragStart = ctx.transformedPoint(this.lastX, this.lastY);

            this.dragged = false;
        },

        onMouseMove(evt) {
            let canvas = this.canvas;
            let dragstart = this.dragStart;
            let ctx = this.ctx;

            this.lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft);
            this.lastY = evt.offsetY || (evt.pageY - canvas.offsetTop);
            this.dragged = true;

            if (dragstart) {
                let pt = ctx.transformedPoint(this.lastX, this.lastY);
                ctx.translate(pt.x - dragstart.x, pt.y - dragstart.y);
                this.draw();
            }
        },

        onMouseUp() {
            this.dragStart = null;
        },

        onMouseWheel(evt) {
            let delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;

            if (delta) {
                this.zoom(delta);
            }
        },

        onClickZoomIn() {
            this.zoom(4, { center: true });
        },

        onClickZoomOut() {
            this.zoom(-4, { center: true });
        },

        resize() {
            let width = this.$el.offsetWidth;
            let height = this.$el.offsetHeight;
            const canvas = this.canvas;

            canvas.width = width;
            canvas.height = height;
            this.ctx.setTransform(1, 0, 0, 1, 0, 0);
            this.draw();
        },

        clear() {
            let canvas = this.canvas;
            let ctx = this.ctx;

            let p1 = ctx.transformedPoint(0, 0);
            let p2 = ctx.transformedPoint(canvas.width, canvas.height);
            ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
            ctx.save();

            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.restore();
        },

        zoom(clicks, { center = false } = {}) {
            const scaleFactor = 1.02;
            const ctx = this.ctx;
            const canvas = this.canvas;

            const minZoom = 1.0;
            let t = ctx.getTransform();

            if (t.a < minZoom) {
                this.resize();

                return;
            }

            let pt;

            if (!center) {
                pt = ctx.transformedPoint(this.lastX, this.lastY);
            } else {
                pt = ctx.transformedPoint(
                    canvas.offsetLeft + canvas.offsetWidth / 2,
                    canvas.offsetTop + canvas.offsetHeight / 2,
                );
            }
            ctx.translate(pt.x, pt.y);
            let factor = Math.pow(scaleFactor, clicks);
            ctx.scale(factor, factor);
            ctx.translate(-pt.x, -pt.y);
            this.draw();
        },

        draw() {
            const img = this.img;

            if (!img) {
                return;
            }

            const canvas = this.canvas;
            const ctx = this.ctx;

            // Clear the entire canvas
            let p1 = ctx.transformedPoint(0, 0);
            let p2 = ctx.transformedPoint(canvas.width, canvas.height);
            ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
            ctx.save();

            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.restore();

            let imgAspectRatio = img.width / img.height;
            let destWidth = img.width < canvas.width ? img.width : canvas.width;
            let destHeight = img.height < canvas.height ? img.height : canvas.height;

            let destAspectRatio = destWidth / destHeight;

            if (destAspectRatio > imgAspectRatio) {
                destWidth = img.width * destHeight / img.height;
            } else {
                destHeight = img.height * destWidth / img.width;
            }

            let offsetx = (canvas.width / 2) - (destWidth / 2);
            let offsety = (canvas.height / 2) - (destHeight / 2);
            let destx = offsetx > 0 ? offsetx : 0;
            let desty = offsety > 0 ? offsety : 0;

            this.ctx.drawImage(img,
                0, 0, img.width, img.height,
                destx, desty, destWidth, destHeight);
        },
    },
};
