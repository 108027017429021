import axios from 'axios';
import { getValue, setValue, removeKey } from '../../../libs/storage.js';
import { logThrowApiError } from '../../../libs/kk-api';

function addSelectLabel(address) {
    return { ...address, selectLabel: address.label || address.address_line_1 };
}

export default {
    namespaced: true,
    state: {
        contacts: [],
        meta: null,
        editing: null,
        selectedContacts: [],
        currentTab: 'Kontakter',
        settings: null,
        customerNumberDisabled: false,
        vendorNumberDisabled: false,
        contactData: {
            categories: null,
            countries: null,
        },
        loading: {
            isFetchingContacts: false,
            isFetchingData: false,
            initLoading: false,
            saveSettings: false,
        },
        filter: {
            include: 'addresses.country,categories,persons,projects,defaultDeliveryAddress,defaultInvoiceAddress,files',
            display: 15,
            page: 1,
            search: null,
            type: null,
            categories: [],
            customers: null,
            vendors: null,
        },
    },
    mutations: {
        UPDATE_CONTACTS(state, contacts) {
            state.contacts = contacts.map(contact => ({
                ...contact,
                default_invoice_address: contact.default_invoice_address
                    ? addSelectLabel(contact.default_invoice_address)
                    : null,
                default_delivery_address: contact.default_delivery_address
                    ? addSelectLabel(contact.default_delivery_address)
                    : null,
                addresses: contact.addresses.map(addSelectLabel),
            }));
        },
        UPDATE_FETCHING_STATUS(state, status) {
            state.loading.isFetchingContacts = status;
        },
        UPDATE_FETCHING_DATA_STATUS(state, status) {
            state.loading.isFetchingData = status;
        },
        UPDATE_INITIAL_LOADING(state, status) {
            state.loading.initLoading = status;
        },
        UPDATE_SAVE_SETTINGS_STATUS(state, status) {
            state.loading.saveSettings = status;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        UPDATE_TAB(state, tab) {
            state.currentTab = tab;
        },
        SET_CONTACT(state, id) {
            state.editing = state.contacts.find(x => x.id === id);
        },
        SET_CONTACT_WITH_DATA(state, data) {
            const contact = data;

            // selectLabel is an FE only key for showing the selected address in the v-selects for
            // default delivery and invoice addresses based on which fields have been used when creating it.
            // Here we create this key if it doesn't already exist to stop the v-selects from complaining
            // and acting weird;

            if (contact.default_delivery_address && !Object.hasOwn(contact.default_delivery_address, 'selectLabel')) {
                contact.default_delivery_address.selectLabel = contact.default_delivery_address.label
                || contact.default_delivery_address.address_line_1
                || contact.default_delivery_address.city;
            }

            if (contact.default_invoice_address && !Object.hasOwn(contact.default_invoice_address, 'selectLabel')) {
                contact.default_invoice_address.selectLabel = contact.default_invoice_address.label
                || contact.default_invoice_address.address_line_1
                || contact.default_invoice_address.city;
            }

            state.editing = contact;
        },
        SET_DATA(state, payload) {
            state.contactData = payload;
        },
        SET_CATEGORIES(state, payload) {
            state.contactData.categories = payload;
        },
        SET_SETTINGS(state, payload) {
            state.settings = payload.data;
        },
        CLOSE_CONTACT(state) {
            state.editing = null;
        },
        SET_VENDORS(state) {
            state.filter.vendors = true;
            state.filter.customers = false;
        },
        UPDATE_FILTER(state, payload) {
            state.filter[payload.filter] = payload.value;
        },
        RESET_FILTER(state, userId) {
            state.filter.search = '';
            removeKey('filters.search', userId, { namespace: 'contacts' });
            state.filter.categories = [];
            removeKey('filters.categories', userId, { namespace: 'contacts' });
        },
        UPDATE_CUSTOMER_NUMBER(state, payload) {
            state.settings['contact.first_customer_number'] = payload;
        },
        UPDATE_VENDOR_NUMBER(state, payload) {
            state.settings['contact.first_vendor_number'] = payload;
        },
        SET_CUSTOMER_NUMBER_DISABLED(state, payload) {
            state.customerNumberDisabled = payload;
        },
        SET_VENDOR_NUMBER_DISABLED(state, payload) {
            state.vendorNumberDisabled = payload;
        },
    },
    actions: {
        openContact({ commit }, id) {
            commit('SET_CONTACT', id);
        },
        async getSingleContactAndOpen({ commit, state }, id) {
            try {
                const contact = await axios.get(`/api/contacts/${id}`, { params: state.filter });
                commit('SET_CONTACT_WITH_DATA', contact.data);
            } catch (error) {
                console.error(error);
            }
        },
        closeContact({ commit }) {
            commit('CLOSE_CONTACT');
        },
        async updateTab({ commit, dispatch }, tab) {
            if (tab === 'Kontakter') {
                await commit('UPDATE_FILTER', { filter: 'customers', value: null });
                await commit('UPDATE_FILTER', { filter: 'vendors', value: null });
            } else if (tab === 'Kunder') {
                await commit('UPDATE_FILTER', { filter: 'customers', value: true });
                await commit('UPDATE_FILTER', { filter: 'vendors', value: null });
            } else if (tab === 'Leverandører') {
                await commit('UPDATE_FILTER', { filter: 'customers', value: null });
                await commit('UPDATE_FILTER', { filter: 'vendors', value: true });
            }
            await commit('UPDATE_TAB', tab);
            dispatch('fetchContacts');
        },
        async updateFilter({ commit, dispatch, rootGetters }, { filter, value }) {
            const userId = rootGetters.getUserId;
            await commit('UPDATE_FILTER', { filter, value });
            await commit('UPDATE_FILTER', { filter: 'page', value: 1 });

            await setValue(`filters.${filter}`, value, userId, { namespace: 'contacts' });
            await dispatch('fetchContacts');
        },
        async changePage({ commit, dispatch }, page) {
            await commit('UPDATE_FILTER', { filter: 'page', value: page });
            await dispatch('fetchContacts');
        },
        async resetFilter({ commit, dispatch, rootGetters }) {
            const userId = await rootGetters.getUserId;

            await commit('RESET_FILTER', userId);
            await dispatch('fetchContacts');
        },
        async createNewContact({ commit }) {
            let newContact = {
                name: '',
                contact_type: 'COMPANY',
                categories: [],
                addresses: [],
                persons: [],
                is_vendor: false,
                is_customer: false,
            };

            commit('SET_CONTACT_WITH_DATA', newContact);
        },
        async getInitContacts({ commit, state }) {
            await commit('UPDATE_INITIAL_LOADING', true);
            let filter = state.filter;

            const contacts = await axios.get('/api/contacts', { params: filter });
            await commit('UPDATE_CONTACTS', contacts.data.data);
            await commit('UPDATE_META', contacts.data.meta);
            await commit('UPDATE_INITIAL_LOADING', false);
        },
        async fetchContacts({ commit, state }, userId) {
            await commit('UPDATE_FETCHING_STATUS', true);

            if (userId) {
                const display = await getValue('filters.display', userId, { namespace: 'contacts', defaultValue: 15 });
                await commit('UPDATE_FILTER', { filter: 'display', value: display });
            }

            let filter = state.filter;

            try {
                const contacts = await axios.get('/api/contacts', {
                    params: { ...filter, categories: filter.categories.join() },
                });
                await commit('UPDATE_CONTACTS', contacts.data.data);
                await commit('UPDATE_META', contacts.data.meta);
                commit('UPDATE_FETCHING_STATUS', false);
            } catch (error) {
                console.error(error);
                commit('UPDATE_FETCHING_STATUS', false);
            }
        },
        async fetchCategories({ commit }) {
            try {
                const categories = await axios.get('/api/contact-categories');
                commit('SET_CATEGORIES', categories.data.map(o => ({ ...o, children: [] })));
            } catch (error) {
                console.error(error);
            }
        },
        async fetchData({ commit }) {
            await commit('UPDATE_FETCHING_DATA_STATUS', true);

            try {
                const categories = await axios.get('/api/contact-categories');
                const countries = await axios.get('/api/countries');
                const newCategories = categories.data.map(o => ({ ...o, children: [] }));
                commit('SET_DATA', { categories: newCategories, countries: countries.data });
                await commit('UPDATE_FETCHING_DATA_STATUS', false);
            } catch (error) {
                console.error(error);
            }
        },
        async bulkDeleteContacts({ dispatch, commit }, data) {
            commit('UPDATE_FETCHING_STATUS', true);

            try {
                await axios.request({ data, url: '/api/contacts', method: 'delete' });
                await dispatch('fetchContacts');
            } catch (error) {
                console.error(error);
                commit('UPDATE_FETCHING_STATUS', false);
            }
        },
        async fetchSettings({ commit }) {
            const settings = await axios.get('/api/contacts/settings/all');
            commit('SET_SETTINGS', settings);

            if (settings.data['contact.first_customer_number'] !== null) {
                commit('SET_CUSTOMER_NUMBER_DISABLED', true);
            }

            if (settings.data['contact.first_vendor_number'] !== null) {
                commit('SET_VENDOR_NUMBER_DISABLED', true);
            }
        },
        async saveSettings({ dispatch, commit, state }) {
            commit('UPDATE_SAVE_SETTINGS_STATUS', true);

            try {
                await axios.put('/api/contacts/settings/all', state.settings);
                await dispatch('fetchSettings');
            } catch (error) {
                logThrowApiError(error, '/api/contacts/settings/all', state.settings, true);
            } finally {
                commit('UPDATE_SAVE_SETTINGS_STATUS', false);
            }
        },
    },
};
