<template>
    <div v-if="type === 'normal'" class="new-address-form">
        <div v-if="tempNewAddress" class="new-address-content">
            <label class="form-label">
                {{ trans('contacts.Tittel') }}
                <input v-model="tempNewAddress.label" type="text">
            </label>

            <label class="form-label">
                {{ trans('contacts.Adresse linje 1') }}
                <input v-model="tempNewAddress.address_line_1" type="text">
            </label>

            <label class="form-label">
                {{ trans('contacts.Adresse linje 2') }}
                <input v-model="tempNewAddress.address_line_2" type="text">
            </label>

            <label class="form-label">
                {{ trans('contacts.Postnr') }} <small>*</small>
                <input v-model="tempNewAddress.postal_code" type="text">
            </label>
            <label class="form-label">
                {{ trans('contacts.Poststed') }} <small>*</small>
                <input v-model="tempNewAddress.city" type="text">
            </label>

            <label v-if="countriesData" class="form-label">
                {{ trans('contacts.Land') }} <small>*</small>
                <mobile-vue-select
                    v-model="tempNewAddress.country"
                    :placeholder="trans('contacts.Velg land')"
                    :options="countriesData.countries"
                    label="name"
                    class="selector"
                    autocomplete="chrome-off"
                />
            </label>
        </div>
        <kk-button
            big
            :disabled="disableSaveButton || isSaving"
            @click="saveNewAddress"
        >
            {{ trans('shared.Legg til') }}
        </kk-button>
    </div>
    <div v-else>
        <kk-modal
            v-if="createNewAddress"
            class="new-address-modal"
            :close-button="true"
            :header="true"
            :footer="true"
            @click-outside="clickOutside()"
            @cancel="cancelNewAddress()"
        >
            <template #title>
                <div>
                    <h2>{{ trans('contacts.Ny') }} {{ trans('contacts.adresse') }}</h2>
                </div>
            </template>
            <template #content>
                <div>
                    <div class="new-address-content">
                        <label class="form-label">
                            {{ trans('contacts.Tittel') }}
                            <input v-model="tempNewAddress.label" type="text">
                        </label>

                        <label class="form-label">
                            {{ trans('contacts.Adresse linje 1') }}
                            <input v-model="tempNewAddress.address_line_1" type="text">
                        </label>

                        <label class="form-label">
                            {{ trans('contacts.Adresse linje 2') }}
                            <input v-model="tempNewAddress.address_line_2" type="text">
                        </label>

                        <label class="form-label">
                            {{ trans('contacts.Postnr') }} <small>*</small>
                            <input v-model="tempNewAddress.postal_code" type="text">
                        </label>
                        <label class="form-label">
                            {{ trans('contacts.Poststed') }} <small>*</small>
                            <input v-model="tempNewAddress.city" type="text">
                        </label>

                        <label class="form-label">
                            {{ trans('contacts.Land') }} <small>*</small>
                            <v-select
                                v-model="tempNewAddress.country"
                                :placeholder="trans('contacts.Velg land')"
                                :options="countriesData.countries"
                                label="name"
                                class="selector"
                                autocomplete="chrome-off"
                            />
                        </label>
                    </div>
                </div>
            </template>
            <template #ok-button>
                <kk-button
                    :disabled="disableSaveButton || isSaving"
                    type="primary"
                    icon
                    class="footer-button"
                    @click="saveNewAddress"
                >
                    {{ 'shared.OK' | trans }}
                </kk-button>
            </template>
        </kk-modal>
    </div>
</template>

<script>
import { trans } from '../../mixin/trans';

import vSelect from 'vue-select';
import kkModal from '../kk-modal/kk-modal.vue';
import kkButton from '../kk-button/kk-button.vue';
import contactsStore from '../../../../vue/views/contacts/stores/contacts-store.js';
import mobileVueSelect from '../../../../vue/components/mobile-vue-select/mobile-vue-select.vue';
import ks from '../../lib/ks';

import { mapState, mapActions } from 'vuex';

const emptyAddress = () => (
    {
        label: null,
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        city: null,
        country: { id: 1, name: 'Norway', code: 'NO', calling_code: '47' },
    }
);

export default {
    name: 'new-address',
    components: {
        kkModal,
        kkButton,
        vSelect,
        mobileVueSelect,
    },
    mixins: [trans],
    props: {
        contactId: {
            type: Number,
            default: 0,
        },
        createNewAddress: {
            type: Boolean,
            default: false,
        },
        modalTitle: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'modal', // Type of the component "modal" or "normal"
            validator: (value) => {
                return ['modal', 'normal'].includes(value);
            },
        },
    },
    data() {
        return {
            tempNewAddress: emptyAddress(),
            isSaving: false,
        };
    },
    computed: {
        ...mapState({
            countriesData: state => state.contactsStore.contactData,
        }),
        disableSaveButton() {
            return !(this.tempNewAddress?.postal_code && this.tempNewAddress?.city && this.tempNewAddress?.country);
        },
    },
    beforeCreate() {
        if (!this.$store.hasModule('contactsStore')) {
            this.$store.registerModule('contactsStore', contactsStore);
        }
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        ...mapActions('contactsStore', [
            'fetchData',
        ]),
        emptyForm() {
            this.tempNewAddress = emptyAddress();
        },
        cancelNewAddress() {
            this.emptyForm();
            this.$emit('cancel-address', false);
        },
        async saveNewAddress() {
            this.isSaving = true;

            if (!(this.tempNewAddress.postal_code && this.tempNewAddress.city && this.tempNewAddress.country)) {
                ks.alert(
                    this.trans('errors.Feil'),
                    this.trans('contacts.Du må fylle ut postnr, poststed, og land!'),
                );
                this.isSaving = false;

                return;
            }
            const payload = {
                country_id: this.tempNewAddress.country.id,
                label: this.tempNewAddress.label,
                address_line_1: this.tempNewAddress.address_line_1,
                address_line_2: this.tempNewAddress.address_line_2,
                postal_code: this.tempNewAddress.postal_code,
                city: this.tempNewAddress.city,
            };
            await ks.post(`/api/contacts/${this.contactId}/addresses`, payload)
                .then((response) => {
                    this.isSaving = false;
                    this.$emit('new-address', response.data);
                    this.emptyForm();
                })
                .catch((error) => {
                    console.error(error);
                    ks.alert(
                        this.trans('project-creation.Feil inndata'),
                        `${this.trans('project-creation.Vi ser du har sendt noe vi ikke forventet')}. <br><br> ${this.trans('project-creation.Vennligst se gjennom skjema og rett opp i feil')}`,
                    );
                    this.isSaving = false;
                });
        },
        clickOutside() {
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/desktop.scss';

.new-address-modal {
    .new-address-content {
        min-width: 400px;
        display: flex;
        flex-direction: column;
    }
}
.form-label {
    color: #4A4A4A;
    font-size: 14px;
    letter-spacing: 0.17px;
    line-height: 16px;
    display: block;
    margin: 10px 0;
    height: auto;
    input, .v-select {
        margin: 5px 0 0 0;
    }
    small {
        color: $red;
    }

    .tooltip {
        display: inline;
    }
}
input {
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    border: 1px solid #D6D5D5;
    border-radius: 3px;
    background-color: #FFFFFF;
    font-family: "Work Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0.15px;
    padding-left: 10px;
    resize: none;
    &:disabled {
        background: #f4f4f4;
    }
}
input[disabled='disabled'], input[disabled='disabled']:hover,
input[disabled='disabled']:active {
    padding: unset;
    box-shadow: unset;
    color: unset;
    background: #f4f4f4;
}
.v-select::v-deep {
    .vs__dropdown-menu {
        max-height: 150px !important;
    }
}
.new-address-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    button {
        margin-bottom: 10px;
    }
}

</style>
