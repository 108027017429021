/**
 * We need to load babel-polyfill to rewrite some core functionality like
 * Promise / Array.from / Array.prototype.includes, findindex etc.
 * CONS; This makes the overall buildsize a bit larger.
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Moment
import moment from 'moment/min/moment-with-locales';
import vueMoment from 'vue-moment';

// Separate init files
import { initSentry } from './sentry.js';
import { initServiceWorker } from './service-worker.js';
import './font-awesome.js';
import { initMixpanel } from './mixpanel.js';

import axios from 'axios';
import Vue from 'vue';

// Axios setup
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Set up initial CSRF token in axios.
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

const lang = document.querySelector('html').getAttribute('lang');

if (lang) {
    try {
        moment.locale(lang);
    } catch {
        // Catch - ie. just use the default languag (en)

        console.warn('Could not load moment locale: ', lang);
    }
}

try {
    initSentry();
} catch (error) {
    console.warn('Sentry failed:  ', error);
}

try {
    initMixpanel();
} catch (error) {
    console.warn('Mixpanel failed:  ', error);
}

initServiceWorker();

Vue.use(vueMoment, { moment });
window.moment = moment;

/* global process */
if (process.env.NODE_ENV === 'production') {
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
}
