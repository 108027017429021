export const DateRangeOptionTypes = {
    CUSTOM: 0,
    TODAY: 1,
    YESTERDAY: 2,
    LAST_WEEK: 3,
    LAST_MONTH: 4,
    LAST_7_DAYS: 5,
    LAST_30_DAYS: 6,
    LAST_3_MONTHS: 7,
    LAST_6_MONTHS: 8,
    LAST_12_MONTHS: 9,
    YEAR_TO_DATE: 10,
    THIS_WEEK: 11,
    THIS_MONTH: 12,
    LAST_QUARTER: 13,
    THIS_QUARTER: 14,
    LAST_YEAR: 15,
};

export const getDateRangeOptions = (currentPeriodStart, currentPeriodEnd, defaultDateFormat) => {
    const lang = document.querySelector('html').getAttribute('lang');

    moment.updateLocale(lang, {
        week: {
            dow: 1,
        },
    });

    const options = [
        {
            id: DateRangeOptionTypes.CUSTOM,
            start: currentPeriodStart,
            end: currentPeriodEnd,
            title: Lang.get('shared.Egendefinert'),
            disabled: true,
        },
        {
            id: DateRangeOptionTypes.TODAY,
            start: moment(),
            end: moment(),
            title: Lang.get('timereg-list.I dag'),
        },
        {
            id: DateRangeOptionTypes.YESTERDAY,
            start: moment().subtract(1, 'days'),
            end: moment().subtract(1, 'days'),
            title: Lang.get('timereg-list.I går'),
        },
        {
            id: DateRangeOptionTypes.THIS_WEEK,
            start: moment().startOf('week'),
            end: moment().endOf('week'),
            title: Lang.get('shared.Denne uken'),
        },
        {
            id: DateRangeOptionTypes.LAST_WEEK,
            start: moment().subtract(1, 'weeks').startOf('week'),
            end: moment().subtract(1, 'weeks').endOf('week'),
            title: Lang.get('timereg-list.Forrige uke'),
        },
        {
            id: DateRangeOptionTypes.THIS_MONTH,
            start: moment().startOf('month'),
            end: moment().endOf('month'),
            title: Lang.get('shared.Denne måneden'),
        },
        {
            id: DateRangeOptionTypes.LAST_MONTH,
            start: moment().subtract(1, 'months').startOf('month'),
            end: moment().subtract(1, 'months').endOf('month'),
            title: Lang.get('timereg-list.Forrige måned'),
        },
        {
            id: DateRangeOptionTypes.LAST_7_DAYS,
            start: moment().subtract(6, 'days'),
            end: moment(),
            title: Lang.get('shared.Siste :count dager', { count: 7 }),
        },
        {
            id: DateRangeOptionTypes.LAST_30_DAYS,
            start: moment().subtract(29, 'days'),
            end: moment(),
            title: Lang.get('shared.Siste :count dager', { count: 30 }),
        },
        {
            id: DateRangeOptionTypes.LAST_QUARTER,
            start: moment().subtract(1, 'quarters').startOf('quarter'),
            end: moment().subtract(1, 'quarters').endOf('quarter'),
            title: Lang.get('shared.Forrige kvartal'),
        },
        {
            id: DateRangeOptionTypes.THIS_QUARTER,
            start: moment().startOf('quarter'),
            end: moment().endOf('quarter'),
            title: Lang.get('shared.Dette kvartal'),
        },
        {
            id: DateRangeOptionTypes.LAST_3_MONTHS,
            start: moment().subtract(3, 'months').startOf('month'),
            end: moment().subtract(1, 'months').endOf('month'),
            title: Lang.get('shared.Siste :count måneder', { count: 3 }),
        },
        {
            id: DateRangeOptionTypes.LAST_6_MONTHS,
            start: moment().subtract(6, 'months').startOf('month'),
            end: moment().subtract(1, 'months').endOf('month'),
            title: Lang.get('shared.Siste :count måneder', { count: 6 }),
        },
        {
            id: DateRangeOptionTypes.LAST_12_MONTHS,
            start: moment().subtract(12, 'months').startOf('month'),
            end: moment().subtract(1, 'months').endOf('month'),
            title: Lang.get('shared.Siste :count måneder', { count: 12 }),
        },
        {
            id: DateRangeOptionTypes.LAST_YEAR,
            start: moment().subtract(1, 'years').startOf('year'),
            end: moment().subtract(1, 'years').endOf('year'),
            title: Lang.get('shared.I fjor'),
        },
        {
            id: DateRangeOptionTypes.YEAR_TO_DATE,
            start: moment().startOf('year'),
            end: moment(),
            title: Lang.get('shared.Hittil i år'),
        },
    ];

    return defaultDateFormat
        ? options.map((option) => {
            if (!option.start || !option.end) {
                return option;
            }

            return {
                ...option,
                ...{
                    start: option.start.format(defaultDateFormat),
                    end: option.end.format(defaultDateFormat),
                },
            };
        })
        : options;
};
