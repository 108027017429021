import axios from 'axios';
import {
    trans,
} from '../filters/';
import '../sass/ks.scss';

export default {
    /**
     * Show alert dialog
     * @param {String|Object} title Title for alert OR alert object
     * @param {String} [title.title] Alert title
     * @param {String} [title.message] Alert message
     * @param {String} [title.okText] OK button's text
     * @param {Function} [title.callback] Callback to fire after OK button click
     * @param {String} [message] Message
     * @param {Function} [callback] Callback to fire after OK button click
     * @param {String} [okText] (optional) OK button's text
     * @returns {Promise}
     */
    alert(title, message, callback, okText) {
        return new Promise((resolve) => {
            let config = {
                title: title ?? '',
                message: message ?? '',
                okText: okText ?? trans('shared.OK'),
                callback,
            };

            if (typeof arguments[0] === 'object' && arguments[0] !== null) {
                config = {
                    ...config,
                    ...arguments[0],
                };
            }

            const alert = document.createElement('div');
            alert.classList.add('kk-alert');
            alert.innerHTML = `
                <div class="kk-alert-dialog">
                    <h2 class="kk-alert-dialog-title">${config.title}</h2>
                    <p>${config.message}</p>

                    <div class="kk-alert-dialog-footer">
                        <button data-ks-confirm>${config.okText}</button>
                    </div>
                </div>
            `;

            // Run callback on button click and hide dialog
            alert.getElementsByTagName('button')[0].addEventListener('click', () => {
                if (typeof config.callback === 'function') {
                    config.callback();
                }

                resolve();

                // Just for visual transitions
                alert.getElementsByClassName('kk-alert-dialog')[0].style = 'opacity: 0;';

                setTimeout(() => {
                    alert.style = 'opacity: 0;';
                }, 150);

                // Remove the dialog
                setTimeout(() => {
                    if (alert.parentNode) {
                        document.body.removeChild(alert);
                    }
                }, 500);
            });

            document.body.append(alert);
        });
    },

    /**
     * Confirm dialogue
     * @param {String|Object} title Dialog title OR confirm object
     * @param {String} [title.title] Dialogue title
     * @param {String} [title.message=""] Text
     * @param {String} [title.okText] OK button text
     * @param {String} [title.cancelText] Cancel button text
     * @param {String} [title.callback] Callback to fire after OK button click
     * @param {String} [message] Text
     * @param {String} [okText] (optional) OK button's text
     * @param {String} [cancelText] (optional) "project-files.Avbryt" if null in params
     * @param {function} [callback]
     * @returns {Promise}
     */
    confirm(title, message, okText, cancelText, callback) {
        return new Promise((resolve) => {
            let config = {
                title: title ?? '',
                message: message ?? '',
                okText: okText ?? trans('shared.OK'),
                cancelText: cancelText || trans('shared.Avbryt'),
                callback,
            };

            if (typeof arguments[0] === 'object' && arguments[0] !== null) {
                config = {
                    ...config,
                    ...arguments[0],
                };
            }

            const confirm = document.createElement('div');
            confirm.classList.add('kk-confirm');
            confirm.innerHTML = `
                <div class="kk-confirm-dialog">
                    <h2 class="kk-alert-dialog-title">${config.title}</h2>
                    <p>${config.message}</p>

                    <div class="kk-confirm-dialog-footer">
                        <button data-ks-reject>${config.cancelText}</button>
                        <button data-ks-confirm>${config.okText}</button>
                    </div>
                </div>
            `;

            const closeDialog = () => {
                // Just for visual transitions
                confirm.getElementsByClassName('kk-confirm-dialog')[0].style = 'opacity: 0;';

                setTimeout(() => {
                    confirm.style = 'opacity: 0;';
                }, 150);

                // Remove the dialog
                setTimeout(() => {
                    if (confirm.parentNode) {
                        document.body.removeChild(confirm);
                    }
                }, 500);
            };

            // Run callback on button click and hide dialog
            confirm.querySelector('button[data-ks-confirm]').addEventListener('click', () => {
                confirm.querySelector('button[data-ks-confirm]').disabled = true;

                if (typeof config.callback === 'function') {
                    config.callback(true);
                }

                resolve(true);
                closeDialog();
            });

            confirm.querySelector('button[data-ks-reject]').addEventListener('click', () => {
                confirm.querySelector('button[data-ks-reject]').disabled = true;

                if (typeof config.callback === 'function') {
                    config.callback(false);
                }

                resolve(false);
                closeDialog();
            });

            document.body.append(confirm);
        });
    },

    /**
     * Wrapper method for axios.delete calls via this.http
     * @param {String} url
     * @param {Object} params URL Query String parameters
     * @param {Boolean} [alert] whether to display error popup for user if request fails.
     * @param {Function} [callback]
     * @returns {Promise}
     */
    delete(url, params, alert, callback) {
        return this.http('delete', url, {
            data: {
                params: params,
            },
        }, alert, callback);
    },

    /**
     * Wrapper method for mobiscroll alert, but specialized for error-reporting.
     * Reports the contained error-message received from backend
     * @param {Object} error object from http/axios.catch-callback
     * @param {Boolean} [alert=false] show an alert dialog? - true/false
     * @param {Function} [callback]
     * @param {String} [okText='OK']
     */
    error(error, alert = false, callback, okText = 'OK') {
        let title = Lang.get('errors.En feil oppstod!');
        let message = '';
        let callbackData = null;

        if (error.response) {
            const statusCode = '(' + error.response.status + ')';

            if (error.response.data.errors) {
                let errors = [statusCode];
                const fields = error.response.data.errors;

                for (let key in fields) {
                    let val = fields[key][0];

                    if (key.lastIndexOf('.') !== -1) {
                        val = val.replace(key, key.substring(key.lastIndexOf('.') + 1));
                    }

                    errors.push(val);
                }

                message = errors.join('<br/>\n');
            } else if (error.response.data.error) {
                message = [statusCode, error.response.data.error].join('<br/>\n');
            } else if (error.response.data.status) {
                message = error.response.data.status.message
                + ' - ' + error.response.statusText
                + ' (' + error.response.status + ')';
            } else if (error.response.data.message) {
                message = error.response.data.message;
            } else if (error.response.data) {
                message = error.response.data
                + ' (' + error.response.status + ')';
            } else {
                message = error.response.statusText
                + ' (' + error.response.status + ')';
            }

            callbackData = error.response;
        } else if (error.request) {
            // request sent, response not received
            message = Lang.get('errors.Kunne ikke kontakte server!')
            + ' '
            + Lang.get('errors.Kontroller tilkoblingen.');
            callbackData = error.request;
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error);
        }

        if (alert) {
            this.alert({
                title: title,
                message: message,
                okText: okText,
                callback: () => {
                    callback(callbackData);
                },
            });
        } else {
            callback(callbackData);
        }
    },

    /**
     * Wrapper method for axios.get calls via this.http
     * @param {String} url URL
     * @param {Object} params URL params, will be appended as query string
     * @param {Boolean} [alert] whether to display error popup for user if request fails.
     * @param {Function} [callback]
     * @returns {Promise}
     */
    get(url, params, alert, callback) {
        return this.http('get', url, {
            data: {
                params: params,
            },
        }, alert, callback);
    },

    /**
     * Wrapper method for http calls
     * @param {String} verb
     * @param {String} url
     * @param {Object} data
     * @param {Object} data.data data that's going to be sent
     * @param {Object} [data.headers] headers to the request. (OPTIONAL)
     * @param {Boolean} alert - Show alert dialog if there occurs an error.
     * @param {Function} [callback]
     * @returns {Promise}
     */
    http(verb, url, data, alert, callback) {
        // Set data.headers to empty array if not present.
        if (data && !data.headers) {
            data.headers = [];
        }

        return new Promise((resolve, reject) => {
            axios[verb](url, data.data, data.headers)
                .then((response) => {
                    if (callback) {
                        callback(response);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    this.error(error, alert, (response) => {
                        if (data.data) {
                            let keys = Object.keys(data.data);

                            if (keys.includes('redirectOnError')) {
                                let matchCodes = data.data.redirectOnError.matchCodes;

                                if (typeof matchCodes !== 'undefined'
                                    && matchCodes !== null
                                    && matchCodes.length > 0
                                ) {
                                    matchCodes.forEach((code) => {
                                        if (parseInt(code) === parseInt(response.status)) {
                                            window.location.href = data.data.redirectOnError.redirectTo;
                                        }
                                    });

                                    if (callback) {
                                        callback(response);
                                    }
                                    reject(response);
                                } else {
                                    window.location.href = data.data.redirectOnError.redirectTo;
                                }
                            } else {
                                if (callback) {
                                    callback(response);
                                }
                                reject(response);
                            }
                        } else {
                            if (callback) {
                                callback(response);
                            }
                            reject(response);
                        }
                    });
                });
        });
    },

    /**
     *
     * @param {String} fieldName - the name of the input field, fetched from $event.target.name
     * @param {*} fileList - the list of files to be uploaded, fetched from $event.target.files
     * @param {Object} formAttributes - a custom object with any additional fields/post-names
     * @param {Function} callback
     */
    getFormDataObject(fieldName, fileList, formAttributes, callback) {
        const formData = new FormData();

        // Append the files to the formData-object
        for (let i = 0; i < fileList.length; i++) {
            formData.append(fieldName, fileList[i], fileList[i].name);
        }

        // Append custom form attributes from the formAttributes-object
        for (let key in formAttributes) {
            if (Object.prototype.hasOwnProperty.call(formAttributes, key)) {
                formData[key] = formAttributes[key];
                formData.append(key, formAttributes[key]);
            }
        }

        return callback(formData);
    },

    /**
     * Sets the form headers object and the form-data object by appending the files in the file list and
     * also all key, value attributes in the form attributes object - before passing this to ks.post method
     * @param {String} url the upload url
     * @param {String} fieldName the name of the input field, fetched from $event.target.name
     * @param {Object} fileList the list of files to be uploaded, fetched from $event.target.files
     * @param {Object} formAttributes a custom object with any additional fields/post-names
     * @param {String} [inputId] the id of the input field used for uploads
     * @param {Boolean} [alert] whether to display error popup for user if request fails.
     * @param {Function} [callback]
     * @returns {Promise}
     */
    upload(url, fieldName, fileList, formAttributes, inputId, alert, callback) {
        let formHeaders = {
            'Content-Type': 'multipart/form-data',
        };

        return this.getFormDataObject(fieldName, fileList, formAttributes, (formObject) => {
            document.getElementById(inputId).value = '';

            return this.http('post', url, {
                data: formObject,
                headers: formHeaders,
            }, alert, callback);
        });
    },

    /**
     * Wrapper method for axios.post.
     * Callback returns status and data objects from the axios response.
     * @param {String} url POST URL
     * @param {Object} data
     * @param {Boolean} [alert] whether to display error popup for user if request fails.
     * @param {Function} [callback]
     * @returns {Promise}
     */
    post(url, data, alert, callback) {
        return this.http('post', url, {
            data,
        }, alert, callback);
    },

    /**
     * Wrapper method for axios.patch.
     * Callback returns status and data objects from the axios response.
     * @param {String} url patch URL
     * @param {Object} data
     * @param {Boolean} [alert] whether to display error popup for user if request fails.
     * @param {Function} [callback]
     * @returns {Promise}
     */
    patch(url, data, alert, callback) {
        return this.http('patch', url, {
            data,
        }, alert, callback);
    },

    /**
     * Wrapper for axio.put.
     * @param {String} url URL
     * @param {Object} data Data
     * @param {Boolean} [alert]
     * @param {Function} [callback] Callback
     * @returns {Promise}
     */
    put(url, data, alert, callback) {
        return this.http('put', url, {
            data: data,
        }, alert, callback);
    },

    /**
     * Show a toast
     * @param {String|Object} message The message to be displayed, or full toast object
     * @param {String} [message.message=""] The message to be displayed
     * @param {String} [message.type="default"] Type of toast/colour. Can be: primary, success, error, warning.
     * @param {Function} [message.callback] Toast callback, or callback to run after button click
     * @param {String|Boolean} [message.button] Whether or not to enable a "retry" button.
     * If string, it is used as text for the button.
     * @param {String} [message.position] Position of toast. Can be: bottom (default), center, top
     * @param {Number} [duration=2000] (Optional) Duration in ms, default 2000
     * @param {Function} [callback] (Optional) Callback returned when toast hides OR when button is clicked (if enabled)
     * @param {String} [position='bottom'] (Optional) Position of the toast. Default: 'bottom'.
     * Available positions: 'bottom', 'center', 'top'
     * @param {String|Boolean} button (Optional) If true is set, toast will have a 'Retry'-button.
     * Can also provide a string. On click, callback will be run.
     * @param {String} type (Optional) Colour of toast. Can be: primary, success, error, warning.
     * @returns {Promise}
     */
    toast(message, duration, callback, position, button, type) {
        return new Promise((resolve) => {
            let config = {
                message,
                duration: duration ?? 2000,
                callback,
                position: ['bottom', 'center', 'top'].includes(position) ? position : 'bottom',
                button,
                type: ['primary', 'success', 'error', 'warning'].includes(type) ? type : 'default',
            };

            if (typeof arguments[0] === 'object' && arguments[0] !== null) {
                // Old API supported the object key "color" - map this to type
                config.type = arguments[0]?.color ?? config.type;
                config = {
                    ...config,
                    ...arguments[0],
                };
            }

            const toast = document.createElement('div');
            toast.classList.add('kk-toast', config.position, config.type);
            toast.innerHTML = `
                    <div class="kk-toast-content">
                        <p class="${config.button ? '' : 'center'}">${config.message}</p>
                        <button data-kk-toast-action class="kk-toast-action ${config.button && config.callback ? 'show' : ''}">${config.button === true ? 'Retry' : config.button}</button>
                    </div>
                `;

            document.body.append(toast);

            toast.classList.add('show');

            // Timeout handler - to be able to execute timeout early if button is clicked.
            function timeoutHandler() {
                toast.classList.add('hide');
                setTimeout(() => {
                    document.body.removeChild(toast);

                    if (config.callback) {
                        config.callback();
                    }

                    resolve();
                }, 150);
            }

            // Timeout for toast to hide and execute callback if provided.
            const timeout = setTimeout(timeoutHandler, config.duration);

            // Add button eventlistener
            if (config.button && config.callback) {
                const button = toast.querySelector('button[data-kk-toast-action]');

                button.addEventListener('click', () => {
                    clearTimeout(timeout);
                    timeoutHandler();
                });
            }
        });
    },
    /**
     * This method will drop all non-expected characters from a phone number and replace the starting 00 with +.
     * If it's a valid norwegian number (8 chars, starting with 4 or 9) - will return it with the pattern +47{number}
     * Else - will return the number as is
     *
     * NOTE: this pattern follows is applied on the FE and BE, so if you make some changes
     * - make sure to change it on the BE as well
     *
     * @param phoneNumber
     * @returns {*|string}
     */
    normaliseNumber(phoneNumber) {
        // drop all non-numeric characters except '+'
        // drop all '+' characters except the first one
        // replace '00' at the beginning of the number with '+'
        const patterns = [/[^0-9+]/g, /(?!^)\+/g, /^00/];
        const replacements = ['', '', '+'];
        let number = phoneNumber;

        // Apply replacements
        patterns.forEach((pattern, index) => {
            number = number.replace(pattern, replacements[index]);
        });

        let norwegianSubstr = null;

        // if number starts with +47 and it's 11 digits in total - code to be dropped
        // elseif number starts with 47 and it's 10 digits in total - code to be dropped
        // elseif number doesn't start with + - there is no code to be dropped
        if (number.startsWith('+47') && number.length === 11) {
            norwegianSubstr = 3;
        } else if (number.startsWith('47') && number.length === 10) {
            norwegianSubstr = 2;
        } else if (!number.startsWith('+')) {
            norwegianSubstr = 0;
        }

        if (norwegianSubstr !== null) {
            const norwegianNumber = number.substring(norwegianSubstr);

            // if the number is starting with 4 or 9 and it's 8 digits long - it's a valid norwegian number
            if (
                (norwegianNumber.startsWith('4') || norwegianNumber.startsWith('9'))
                && norwegianNumber.length === 8
            ) {
                return '+47' + norwegianNumber;
            }
        }

        return number;
    },
    validateNumber(phoneNumber) {
        const number = this.normaliseNumber(phoneNumber);

        if (!number) {
            return false;
        }

        if (number.length < 8) {
            return false;
        }

        return number;
    },
    isPhoneNumberValid(phoneNumber) {
        if (!phoneNumber) {
            return false;
        }

        return this.validateNumber(phoneNumber) !== false;
    },
};
