<template>
    <kk-tooltip
        class="kk-icon-button"
        :show="!!tooltipText"
        :text="tooltipText"
    >
        <button
            :class="[
                'kk-icon-button-wrapper',
                `kk-icon-button-wrapper-${variant}`,
                `kk-icon-button-wrapper-${size}`,
                `kk-icon-button-wrapper-${variant}-${disabledClass}`,
            ]"
            @click.stop="onClick"
        >
            <fa-icon
                :icon="icon"
                :class="[
                    `kk-icon-button-wrapper-${variant}-${size}`,
                    `kk-icon-button-wrapper-${variant}-${size}-${disabledClass}`,
                ]"
            />
            <span class="sr-only">{{ tooltipText }}</span>
        </button>
    </kk-tooltip>
</template>

<script>
import kkTooltip from '../kk-tooltip/kk-tooltip.vue';

export default {
    name: 'kk-icon-button',
    components: {
        kkTooltip,
    },
    props: {
        size: {
            type: String,
            default: 'medium',
            validator: size => ['large', 'medium', 'small'].includes(size),
        },
        variant: {
            type: String,
            default: 'plain',
            validator: variant => ['plain', 'outlined', 'primary'].includes(variant),
        },
        tooltipText: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // in this format: ['fal', 'icon-name']
        icon: {
            type: Array,
            required: true,
        },
    },
    computed: {
        disabledClass() {
            return this.disabled ? 'disabled' : 'enabled';
        },
    },
    methods: {
        onClick() {
            if (this.disabled) {
                return;
            }

            this.$emit('click', !this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
$icon-button-size: (
    'large': var(--font-size-2xl),
    'medium': var(--font-size-xl),
    'small': var(--font-size-md),
);

$icon-button-padding: (
    'large': var(--spacing-1_5),
    'medium': var(--spacing-1_25),
    'small': var(--spacing-1),
);

$icon-button-variant: (
    'plain': (
        'background': transparent,
        'color': var(--icon-secondary),
        'border': none,
        'disabled-border': none,
        'hover-background': var(--gray-100),
        'active-background': var(--gray-200),
        'disabled-background': transparent,
    ),
    'outlined': (
        'background': transparent,
        'color': var(--icon-secondary),
        'border': 1px solid var(--gray-400),
        'disabled-border': 1px solid var(--gray-300),
        'hover-background': var(--gray-100),
        'active-background': var(--gray-200),
        'disabled-background': transparent,
    ),
    'primary': (
        'background': var(--blue-500),
        'color': #ffffff,
        'border': none,
        'disabled-border': none,
        'hover-background': var(--blue-600),
        'active-background': var(--blue-700),
        'disabled-background': var(--gray-200),
    ),
);

.kk-icon-button {
    .kk-icon-button-wrapper {
        background: transparent;
        border: none;
        cursor: pointer;
        display: inline-flex;
        border-radius: var(--radius-sm);

        @each $size-name, $size-value in $icon-button-padding {
            &-#{$size-name} {
                padding: $size-value;
            }
        }

        @each $variant-name, $variant-value in $icon-button-variant {
            &-#{$variant-name} {
                background: map-get($variant-value, 'background');
                border: map-get($variant-value, 'border');

                &:hover:not(&-disabled) {
                    background: map-get($variant-value, 'hover-background');
                }

                &:active:not(&-disabled) {
                    background: map-get($variant-value, 'active-background');
                }

                &-disabled {
                    background: map-get($variant-value, 'disabled-background') !important;
                    cursor: not-allowed !important;
                    color: var(--text-disabled) !important;
                    border: map-get($variant-value, 'disabled-border') !important;
                }

                @each $size-name, $size-value in $icon-button-size {
                    &-#{$size-name} {
                        color: map-get($variant-value, 'color');
                        font-size: $size-value;
                        min-width: $size-value;

                        &-disabled {
                            color: var(--text-disabled) !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
