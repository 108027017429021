import Holidays from 'date-holidays';

export const getHolyDays = (countryCode, years) => {
    const holidays = new Holidays(countryCode);
    const holidaysByYears = [];

    years.forEach((year) => {
        holidaysByYears.push(...holidays.getHolidays(year));
    });

    return holidaysByYears
        .filter(h => h.type === 'public')
        .map((element) => {
            return {
                name: element.name,
                startDate: new Date(element.start),
                endDate: new Date(element.end),
            };
        });
};
