import { LangMocker } from '../mixin/lang-mocker';

// When testing components locally Lang is not defined,
// It is an external dependency injected into kk-vue-components.
// LangMocker just returns whatever key you put into it.

if (typeof window.Lang === 'undefined') {
    window.Lang = LangMocker;
}

export default function trans(key, replacement) {
    return window.Lang.get(key, replacement);
}
