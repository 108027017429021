import { createNamespacedHelpers } from 'vuex';
export const { mapGetters, mapActions } = createNamespacedHelpers('client');

const getters = {};

const SET_OFFLINE = 'SET_OFFLINE';
const mutations = {
    [SET_OFFLINE]: (state, data) => {
        state.offline = data;
    },
    SET_WARNING_BANNER: (state, data) => {
        state.warningBanner = data;
    },
    SET_BROWSER_BANNER: (state, data) => {
        state.browserBanner = data;
    },
};

const actions = {
    setOffline: async ({ commit }, offline = false) => {
        commit(SET_OFFLINE, offline);
    },
    setWarningBanner: async ({ commit }, state) => {
        commit('SET_WARNING_BANNER', state);
    },
    setBrowserBanner: async ({ commit }, state) => {
        commit('SET_BROWSER_BANNER', state);
    },
};

const state = {
    offline: false,
    warningBanner: {
        enabled: false,
        text: '',
    },
    browserBanner: {
        enabled: false,
    },
};

export const client = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
