<template>
    <span :class="['kk-checkbox', color, mark, shape, size, { disabled }]" @click="onClick">
        <input type="checkbox" :class="['box', { selected: value }]">
        <label v-if="$slots.default" class="label-content">
            <slot />
        </label>
    </span>
</template>
<script>

export default {
    name: 'kk-checkbox',
    props: {
        size: {
            type: String,
            default: 'medium',
            validator: size => ['big', 'medium', 'small'].includes(size),
        },
        color: {
            type: String,
            default: 'success',
            validator: color => ['success', 'warning', 'danger', 'primary'].includes(color),
        },
        shape: {
            type: String,
            default: 'circle',
            validator: shape => ['circle', 'square'].includes(shape),
        },
        mark: {
            type: String,
            default: 'check',
            validator: mark => ['check', 'minus'].includes(mark),
        },
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClick() {
            // Do not emit if checkbox is disalbled
            if (this.disabled) {
                return;
            }

            this.$emit('input', !this.value);
        },
    },
};
</script>

<!-- eslint-disable -->
<style lang="scss" scoped>
@import '~sass/_variables.scss';
@import '../../sass/variables.scss';

$greyBorder: rgb(214,213,213);
$darkGreyBorder: #969696;

.disabled {
    background-color: unset;
}

.kk-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 140ms linear;
    cursor: pointer;

    .box {
        appearance: none;
        border: 1px solid $greyBorder;
        background-color: #fff;
        cursor: inherit;
        margin: 0;
        outline: none;
        transition: background 140ms linear;

        /**
         * IE 10+ & Edge hack, which doesn't support appearance:none
         * @see https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-check
         */
        &::-ms-check {
            display: none;
        }
    }

    .label-content {
        cursor: pointer;
        margin: 0 0 0 5px;
    }
}

@mixin size($type, $size) {
    #{$type} .box {
        flex: 0 0 $size;
        height: $size;
        width: $size;
    }
}

@mixin color($type, $color) {
    #{$type} {
        .box.selected {
            background-color: $color;
            border: 1px solid $color;
        }

        &:hover .box {
            border: 1px solid $darkGreyBorder;
        }
        .box:focus {
            border: 1px solid $darkGreyBorder;
        }

        &.disabled {
            cursor: not-allowed;
        }
        &.disabled .box {
            background-color: $disabled !important;
            cursor: not-allowed;
        }
        &.disabled .label-content {
            cursor: not-allowed;
        }
        &.disabled .box,
        &.disabled .box.selected,
        &.disabled .box:focus,
        &.disabled:hover .box {
            border: 1px solid $disabled;
        }
    }
}

@mixin shape($type, $radius) {
    #{$type} .box {
        border-radius: $radius;
    }
}

@mixin mark($type, $path) {
    #{$type} .box.selected {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' fill-rule='evenodd' viewBox='0 0 33 33'%0A%3E%3Cpath d='#{$path}' stroke='%23fafafa' stroke-linecap='round' stroke-width='3' /%3E%3C/svg%3E%0A");
    }
}

@include size(".big", 33px);
@include size(".medium", 24px);
@include size(".small", 16px);

@include shape('.circle', 50%);
@include shape('.square', 20%);

@include mark(".check", "M9.5 17.3l4.849 4.8 10.507-10.4");
@include mark(".minus", "M10.0,16.5 L23.0,16.5");
/* https://yoksel.github.io/url-encoder/ 10.01.19 */

@include color(".primary", $primary);
@include color(".warning", $warning);
@include color(".success", $success);
@include color(".danger", $danger);
</style>
