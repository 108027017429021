export function initServiceWorker() {
    // Add service worker, if supported
    if (!('serviceWorker' in navigator)) {
        return;
    }

    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/serviceWorker.js', {
            scope: '/mobile',
        }).catch((error) => {
            console.log('Service worker registration failed: ', error);
        });
    });
}
