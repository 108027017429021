<template>
    <div class="kk-loader">
        <div class="spinner-wrapper">
            <svg-icon name="loading" width="5em" height="5em" />
        </div>
        <h3 class="spinner-text">
            {{ text }}
        </h3>
    </div>
</template>

<script>
import svgIcon from '../svg-icon/svg-icon.vue';

export default {
    name: 'kk-loader',
    components: { svgIcon },
    props: {
        text: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.spinner-wrapper {
    z-index: 1000;
    text-align: center;
    color: #0aaef3;
}
.spinner-text {
    font-size: 25px;
    text-align: center;
}
</style>
