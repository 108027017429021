<template>
    <div :class="[typeClass, toastClass, alertContainerClass]">
        <div class="kk-alert-container_icon">
            <fa-icon :class="iconSizeClass" :icon="icon" />
        </div>
        <div v-if="layout === 'inline'">
            <div v-if="text || $slots.text" class="kk-alert-container_sub-text">
                <span v-if="text">{{ text }}</span>
                <slot v-else name="text" />
            </div>
        </div>
        <div v-else class="kk-alert-container_text-container">
            <div class="kk-alert-container_text" :class="{ 'text-margin': subText || $slots.subText }">
                <span v-if="text">{{ text }}</span>
                <slot v-else name="text" />
            </div>
            <div v-if="subText || $slots.subText" class="kk-alert-container_sub-text">
                <span v-if="subText" class="" v-html="subText" />
                <slot v-else name="subText" />
            </div>
            <div v-if="subLink.text" class="kk-alert-container_sub-text">
                <a
                    v-if="subLink.text"
                    :href="subLink.href"
                    @click="() => subLink.action ? subLink.action() : null"
                >
                    {{ subLink.text }}
                </a>
            </div>
        </div>
        <a v-if="closeButtonVisible && layout !== 'inline'" class="kk-alert-container_close-button">
            <fa-icon size="lg" :icon="['fas', 'xmark']" @click="$emit('close')" />
        </a>
    </div>
</template>

<script>
import { FontAwesomeIcon as faIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: {
        faIcon,
    },
    props: {
        // type of the alert component (success, danger, info, warning)
        // necessary for defining the color, background color
        type: {
            type: String,
            default: 'info',
            validator: value => ['success', 'danger', 'warning', 'info'].includes(value),
        },
        // layout of the alert component (block, toast, inline)
        layout: {
            type: String,
            default: 'block',
            validator: value => ['block', 'toast', 'inline'].includes(value),
        },
        text: {
            type: String,
            default: '',
        },
        subText: {
            type: String,
            default: '',
        },
        subLink: {
            type: Object,
            default: () => ({}),
        },
        closeButtonVisible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        typeClass() {
            return `kk-alert-${this.type}`;
        },
        toastClass() {
            return {
                toast: this.layout === 'toast',
            };
        },
        alertContainerClass() {
            return {
                'kk-alert-container': this.layout !== 'inline',
            };
        },
        icon() {
            switch (this.type) {
                case 'success':
                    return ['fas', 'circle-check'];
                case 'danger':
                    return ['fas', 'circle-xmark'];
                case 'warning':
                    return ['fas', 'triangle-exclamation'];
                default:
                    return ['fas', 'circle-info'];
            }
        },
        iconSizeClass() {
            if (this.layout === 'inline') {
                return {
                    small: true,
                };
            }

            return {
                large: true,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.kk-alert-container {
    border-radius: 4px;
    display: flex;
    padding: var(--spacing-2);
    align-items: flex-start;

    &_text {
        font: var(--font-title-sm);
    }

    &_sub-text {
        font: var(--font-body-sm-regular);
    }

    &_close-button {
        color: #111827;
        margin-left: auto;
        cursor: pointer;
    }

    &_text-container {
        display: flex;
        flex-direction: column;
        row-gap: var(--spacing-1);
    }
}

.toast {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -2px rgba(0, 0, 0, 0.05);
    max-width: 640px;
}

$types:
    "kk-alert-success" var(--green-500) var(--green-700) var(--green-800) var(--green-700) var(--green-50) var(--green-300),
    "kk-alert-danger" var(--red-500) var(--red-700) var(--red-800) var(--red-700) var(--red-50) var(--red-300),
    "kk-alert-warning" var(--yellow-500) var(--yellow-700) var(--yellow-800) var(--yellow-700) var(--yellow-50) var(--yellow-300),
    "kk-alert-info" var(--blue-500) var(--blue-700) var(--blue-800) var(--blue-700) var(--blue-50) var(--blue-300);

@each $name, $icon-color, $text-color, $close-button-color, $sub-text-color, $background-color, $border-color in $types {
    .#{$name} {
        display: flex;
        column-gap: var(--spacing-1);

        .kk-alert-container_icon {
            color: $icon-color;
            display: flex;
            align-items: center;

            .small {
                width: 16px;
                height: 16px;
            }

            .large {
                width: 20px;
                height: 20px;
            }
        }

        .kk-alert-container_text {
            color: $text-color;
        }

        .kk-alert-container_close-button {
            color: $close-button-color;
            padding: var(--spacing-0_5);
        }

        .kk-alert-container_sub-text {
            color: $sub-text-color;

            a {
                cursor: pointer;
                color: var(--link-primary);

                &:hover {
                    color: var(--link-primary-hover);
                    text-decoration: underline;
                }
            }
        }
    }

    .kk-alert-container.#{$name} {
        background: $background-color;
        border: 1px solid $border-color;
        column-gap: var(--spacing-1_5);
    }
}
</style>
