<template>
    <div>
        <kk-alert
            v-if="showMaxDaysError && selectedDaysCount > maxDays"
            class="warning"
            type="danger"
            :text="trans('hour-settings.Du har overskredet maks antall på :max dager. Begrens det for å foreta en registrering', { max: maxDays })"
            :close-button-visible="false"
        />

        <p class="info-message">
            {{ trans('absence.Helligdager og helgedager er uthevet i oransje. *') }}
        </p>

        <p v-if="showSelectedDatesCount" class="selected-dates-count">
            ({{ selectedDaysCount === 1
                ? trans('hour-settings.1 dag valgt')
                : trans('hour-settings.:count dager valgt', { count: selectedDaysCount})
            }})
        </p>

        <div class="kk-date-range-modifier" :class="{ 'kk-date-range-modifier_readonly': readonly }">
            <div
                v-for="item in dateItems"
                :key="item.dateKey"
                :class="['kk-date-range-modifier_item', { 'kk-date-range-modifier_item-holiday': item.isHoliday }]"
            >
                <div class="kk-date-range-modifier_item_container" @click="() => dateItemClicked(item)">
                    <span class="kk-date-range-modifier_item_container_date">{{ item.dateText }}</span>
                    &nbsp;-&nbsp;
                    <span class="kk-date-range-modifier_item_container_day">{{ item.day }}</span>
                    &nbsp;
                    <span
                        v-if="item.isHoliday"
                        class="kk-date-range-modifier_item_container_holiday"
                        :title="item.holidayName"
                    >
                        {{ `(${item.holidayName})` }}
                    </span>
                    <kk-checkbox
                        :value="item.selected"
                        :disabled="item.disabled"
                        size="small"
                        color="success"
                        class="kk-date-range-modifier_item_container_checkbox"
                        :class="{ 'kk-date-range-modifier_item_container_checkbox_readonly': readonly }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Holidays from 'date-holidays';
import kkAlert from '../kk-alert/kk-alert.vue';
import kkCheckbox from '../kk-checkbox/kk-checkbox.vue';

const holidays = new Holidays('NO');

export default {
    name: 'kk-date-range-modifier',
    components: {
        kkAlert,
        kkCheckbox,
    },
    props: {
        mobile: {
            type: Boolean,
            default: false,
        },
        startDate: {
            type: String,
            validator: value => moment(value).isValid(),
            default: null,
        },
        endDate: {
            type: String,
            validator: value => moment(value).isValid(),
            default: null,
        },
        // Array of date strings in format 'YYYY-MM-DD' that are initially selected in component
        // Don't pass this array if you want all dates except holidays, in range between startDate & endDate
        // to be automatically selected
        selectedDates: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        // Triggers the error for max days selected
        showMaxDaysError: {
            type: Boolean,
            default: false,
        },
        // The maximum amount of days user can select
        // Validation is dealt with outisde of this component, 40 is the default
        maxDays: {
            type: Number,
            default: 40,
        },
        showSelectedDatesCount: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dateItems: [],
        };
    },
    computed: {
        selectedDaysCount() {
            return this.dateItems?.filter(f => f.selected).map(d => d.dateKey).length;
        },
    },
    watch: {
        startDate: {
            handler() {
                this.initializeDateItems();
            },
            immediate: true,
        },
        endDate: {
            handler() {
                this.initializeDateItems();
            },
            immediate: true,
        },
    },
    methods: {
        initializeDateItems() {
            const startDateMoment = moment(this.startDate, 'YYYY-MM-DD');
            const endDateMoment = moment(this.endDate, 'YYYY-MM-DD');

            this.dateItems = [];

            for (let day = startDateMoment; day.isSameOrBefore(endDateMoment); day.add(1, 'days')) {
                // timezone issues do not use toISOString
                const holidayCheck = holidays.isHoliday(day.format('YYYY-MM-DDT00:00:00.000') + 'Z');
                const holidayType = holidayCheck?.[0]?.type;
                const isPublicOrBankHoliday = holidayType === 'public' || holidayType === 'bank';
                const isoWeekday = day.isoWeekday();
                const isWeekend = isoWeekday === 6 || isoWeekday === 7; // 6 = Saturday, 7 = Sunday
                const date = day.format('YYYY-MM-DD');
                let holidayName = '';
                let selected = !isPublicOrBankHoliday && !isWeekend;

                if (isPublicOrBankHoliday) {
                    holidayName = holidayCheck?.[0]?.name;
                } else if (isWeekend) {
                    holidayName = this.trans('shared.Helg');
                }

                if (this.selectedDates?.length) {
                    if (this.selectedDates.includes(date)) {
                        selected = true;
                    } else {
                        selected = false;
                    }
                }

                this.dateItems.push({
                    dateKey: date,
                    dateText: this.mobile ? day.format('DD.MM.YY') : day.format('DD.MM.YYYY'),
                    day: this.mobile ? day.format('ddd') : day.format('dddd'), // Monday, Tuesday, ... format
                    selected,
                    isHoliday: isPublicOrBankHoliday || isWeekend,
                    holidayName: holidayName,
                    holidayType,
                });
            }

            // add 'disabled' property to start and end date
            const size = this.dateItems.length;

            if (size > 0) {
                this.dateItems[0].disabled = true;
                this.dateItems[size - 1].disabled = true;
                this.dateItems[0].selected = true;
                this.dateItems[size - 1].selected = true;
            }

            this.selectedDatesChanged();
        },
        dateItemClicked(item) {
            if (!item.disabled && !this.readonly) {
                item.selected = !item.selected;
                this.selectedDatesChanged();
            }
        },
        selectedDatesChanged() {
            this.$emit(
                'update:selected-dates',
                this.dateItems?.filter(f => f.selected).map(d => d.dateKey),
            );
            this.$emit(
                'update:date-items',
                this.dateItems,
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables.scss';

.warning {
    margin-top: 10px;
}

.info-message {
    color: rgba(243, 156, 18, 1);
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.selected-dates-count {
    margin-bottom: 10px;
}

.kk-date-range-modifier {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-height: 400px;
    overflow: auto;
    cursor: pointer;

    &_readonly {
        cursor: not-allowed !important;
    }

    &_item {
        display: flex;
        border-radius: 2px;
        border: 0.5px solid #ddd;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
        height: 40px;

        &_container {
            display: flex;
            align-items: center;
            padding: 6px 8px;
            width: 100%;

            &_date {
                color: #4a4a4a;
                font-size: 11px;
            }

            &_day {
                color: #4a4a4a;
                font-size: 11px;
                text-transform: capitalize;
            }

            &_holiday {
                color: #4a4a4a;
                font-size: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &_checkbox {
                margin-left: auto;

                &_readonly {
                    cursor: not-allowed !important;
                }
            }
        }
    }
    &_item-holiday {
        background: rgba(243, 156, 18, 0.5) !important;
    }
}
</style>
