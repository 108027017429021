// Frontend for localStorage. Uses try/catch and automatically stringifies/parses the JSON.
// Used when needing direct interaction with window.localStorage.

/**
 * Get from localStorage, if supported
 * @export
 * @param {string} key Key in localStorage to get
 * @return {boolean|null}
 */
export function getFromLocalStorage(key) {
    if (!('localStorage' in window)) {
        return null;
    }

    try {
        return JSON.parse(window.localStorage.getItem(key));
    } catch {
        return null;
    }
}

/**
 * Set in localStorage, if supported
 * @export
 * @param {string} key Key in localStorage
 * @param {any} value Value to store
 * @return {boolean} Successfulness
 */
export function setInLocalStorage(key, value) {
    try {
        localStorage.setItem(key, JSON.stringify(value));

        return true;
    } catch {
        return false;
    }
}

/**
 * Remove from localStorage, if supported
 * @export
 * @param {String} key Key to remove
 * @returns {Boolean} Successfulness
 */
export function removeFromLocalStorage(key) {
    try {
        localStorage.removeItem(key);

        return true;
    } catch {
        return false;
    }
}
