<template>
    <div class="kk-input" :class="{ full }">
        <kk-form-title :title="label" :sub-title="subLabel" :color="labelColor" />
        <input
            ref="input"
            :type="inputType"
            :value="display"
            v-bind="$attrs"
            v-on="listeners"
            @input="emitInput"
        >
        <span v-if="error" class="kk-input-error">{{ error }}</span>
        <span v-else-if="useErrorSpace" class="kk-input-error" />
    </div>
</template>

<script>
import kkFormTitle from '../kk-form-title/kk-form-title.vue';

export default {
    name: 'kk-input',

    components: { kkFormTitle },

    props: {
        decimals: {
            default: 4,
            type: Number,
            validator(value) {
                return value >= 0;
            },
        },

        minus: {
            type: Boolean,
            default: false,
        },

        value: {
            type: [String, Number, BigInt],
            default: null,
        },

        /**
        * Label/title text for input.
        */
        label: {
            type: String,
            default: '',
        },

        /**
        * Color of label
        */
        labelColor: {
            type: String,
            default: '#25befe',
        },

        /**
         * Select all text on focus
         */
        selectOnFocus: {
            default: false,
            type: Boolean,
        },

        /**
        * sub-Label/sub-title text.
        * Used to describe title
        */
        subLabel: {
            type: String,
            default: '',
        },

        /**
         * Type of input
         * this is not reflected on the actiual input due to number not accepting comma and
         * type tel displays comma and period as wait and pause.
         */
        type: {
            default: 'text',
            type: String,
            validator(value) {
                return ['text', 'tel', 'number'].indexOf(value) !== -1;
            },
        },

        /**
         * make period automatically become comma
         */
        forceComma: {
            type: Boolean,
            default: true,
        },

        /**
         * make input take whole width (100%)
         */
        full: {
            type: Boolean,
            default: true,
        },
        inputType: {
            default: 'text',
            type: String,
        },
        error: {
            type: String,
            default: null,
        },
        /**
         * in some cases, using error space is needed to align all the form items
         */
        useErrorSpace: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            display: '',
        };
    },

    computed: {
        valueNumber() {
            return (this.value === null) ? '' : String(this.value);
        },

        listeners() {
            var vm = this;

            // `Object.assign` merges objects together to form a new object
            return Object.assign({},
                // We add all the listeners from the parent
                this.$listeners,
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                {
                // This ensures that the component works with v-model
                    input: function() {
                        vm.inputhHandler();
                    },
                    focus: function() {
                        vm.focusHandler();
                    },
                    blur: function() {
                        vm.blurHandler();
                    },
                },
            );
        },
    },

    watch: {
        valueNumber(e) {
            if (e !== this.display) {
                this.display = e;
                this.process();
            }
        },
    },

    mounted() {
        this.update(this.valueNumber);
        this.display = this.valueNumber;

        // In case of delayed props value.
        setTimeout(() => {
            this.update(this.valueNumber);
            this.display = this.valueNumber;
            this.process();

            // Apply thousand separator if numeric value
            if (this.type == 'tel') {
                this.display = this.numberSeparate(this.display);
            } else if (this.type !== 'text') {
                this.display = this.thousandSeparate(this.display);
            }
        }, 500);
    },

    methods: {
        emitInput() {
            this.$emit('input', this.$refs.input.value);
        },
        /**
         * Runs everytime input looses focus
         */
        blurHandler() {
            if (this.type == 'tel') {
                this.display = this.numberSeparate(this.display);
            } else if (this.type !== 'text') {
                // Remove empty comma and minus on blur
                if (this.display && (this.display.slice(-1) === ',' || this.display === '-')) {
                    this.display = this.display.slice(0, -1);
                }

                // Apply thousand separator on blur
                this.display = this.thousandSeparate(this.display);
            }
        },

        focusHandler() {
            // remove seperators on focus
            if (this.display && this.type !== 'text') {
                this.display = this.display.replaceAll(' ', '');
            }

            if (this.selectOnFocus) {
                setTimeout(() => {
                    this.selectInput();
                }, 150);
            }
        },

        /**
         * Runs everytime input gets updated by user.
         */
        inputhHandler() {
            this.display = this.$refs.input.value;
            this.process();
            this.update(this.display);
        },

        /**
         * Remove all characters except numbers, comma and period and first minus (if minus prop is true)
         */
        preventWrongInput() {
            const regex = '([\\.\\,]|\\d)'; // keep comma, period & digits

            // Construct regex & add allowance of minus if minus prop is present.
            let numb = this.display.match(new RegExp(this.minus ? '(^\\-)|' + regex : regex, 'g'));

            if (numb) { // check for empty result
                // Join all together
                this.display = numb.join('');
            } else {
                this.display = null;
            }
        },

        /**
         * Process number input
         */
        process() {
            if (this.type !== 'text' && this.forceComma) {
                this.display = this.replacePeriod(this.display);
                this.display = this.validateDecimals(this.display);
                this.preventWrongInput();
            }
        },

        replacePeriod(v) {
            return v.replace('.', ',');
        },

        selectInput() {
            if (this.$refs.input) {
                this.$refs.input.focus();
                this.$refs.input.select();
            }
        },

        /**
         * Add space between thousand
         */
        thousandSeparate(v) {
            if (v) {
                var parts = v.toString().split(',');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                return parts.join(',');
            }
        },

        /**
         * Add space every 2 digits
         */
        numberSeparate(v) {
            if (v) {
                var parts = v.toString().split(',');
                parts[0] = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ' ');

                return parts.join(',');
            }
        },

        /**
         * Update parent component model value. (value inputted here)
         * Also format to number before emit.
         * @param {String|Number}
         */
        update(value) {
            let x = null;

            if (value && value !== '-' && this.type !== 'text') {
                x = value.replace(',', '.').replace(' ', '');
            } else {
                x = value;
            }

            this.$emit('input', x);
        },

        /**
         * Return input until max decimals
         * (If max is 3 and user inut is 1234.1234 return is 1234.123)
         */
        validateDecimals(v) {
            const decimals = (v.split(',')[1] || []);
            const intengers = v.split(',')[0].length;

            if (decimals.length > 0) {
                return String(v).substr(0, intengers + this.decimals + 1);
            }

            return v;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables';

.kk-input {
    &.full input {
        width: 100%;
    }
    &-error {
        line-height: 35px;
        display: block;
        color: $red;
    }

    input[disabled] {
        cursor: not-allowed;
        background-color: var(--input-background-disabled);
    }
}
</style>
