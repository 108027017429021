import kkInput from '../kk-input/kk-input.vue';
import kkModal from '../kk-modal/kk-modal.vue';
import kkTextarea from '../kk-textarea/kk-textarea.vue';
import ks from '../../lib/ks.js';
import svgIcon from '../svg-icon/svg-icon.vue';
import { trans } from '../../mixin/trans.js';

// FontAwesome
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileArchive } from '@fortawesome/pro-light-svg-icons/faFileArchive';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faFileWord } from '@fortawesome/pro-light-svg-icons/faFileWord';
import { faFileAudio } from '@fortawesome/pro-light-svg-icons/faFileAudio';
import { faFilePowerpoint } from '@fortawesome/pro-light-svg-icons/faFilePowerpoint';
import { FontAwesomeIcon as faIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faFile, faFileArchive, faFileAudio, faFileExcel, faFilePowerpoint, faFilePdf, faFileWord);

// @vue/component
export default {
    name: 'kk-thumbnails',

    components: { faIcon, kkInput, kkModal, kkTextarea, svgIcon },
    mixins: [trans],

    props: {
        files: {
            type: Array,
            default: () => [],
        },

        showActions: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        commentFile: null,
        editFile: null,
        showCommentModal: false,
        showEditModal: false,
    }),

    computed: {
        canLoadMore() {
            return this.files.length < 12;
        },
    },
    methods: {
        /**
         * Handle downloading a file.
         *
         * @param {Object} file
         */
        download(file) {
            // eslint-disable-next-line
            console.log("download()", file);
            // window.location.href = file.src;
        },

        /**
         * Get FontAwesome icon name for a file type, based on its extension.
         *
         * @param {Object} file
         * @returns String
         */
        getIconName(file) {
            let ext = '';

            if (typeof file.name !== 'undefined') {
                ext = file.name.split('.');
                ext = ext[ext.length - 1];
            }

            switch (ext) {
                case 'pdf':
                    return 'file-pdf';

                case 'zip':
                case 'rar':
                case '7z':
                case 'tgz':
                    return 'file-archive';

                case 'doc':
                case 'docx':
                case 'odt':
                    return 'file-word';

                case 'xls':
                case 'xlsx':
                case 'ods':
                    return 'file-excel';

                case 'wav':
                case 'mp3':
                case 'ac3':
                case 'ogg':
                    return 'file-audio';

                case 'ppt':
                case 'pptx':
                case 'odp':
                    return 'file-powerpoint';

                default:
                    return 'file';
            }
        },

        /**
         * Whether or not user has access to edit/comment/delete file
         */
        hasAccess() {
            return true;
        },

        /**
         * Whether or not the file object is an image.
         *
         * @param {Object} file
         * @returns Boolean
         */
        isImage(file) {
            // console.log("isImage()", file)
            if (file.type) {
                return 'image' === file.type;
            }

            if (file.blob && file.blob.type) {
                file.mime = file.blob.type;
            }

            if (file.mime) {
                return file.mime.startsWith('image/') && !file.mime.startsWith('image/svg');
            }

            return false;
        },

        loadedImage(evt, file) {
            this.normalize(file);

            // set width and height
            if ((!file.height || file.width) && evt.target) {
                this.$set(file, 'height', evt.target.naturalHeight);
                this.$set(file, 'width', evt.target.naturalWidth);
            }
        },

        /**
         * Normalize the file objects.
         *
         * @param {Array} files
         */
        normalize(file) {
            if (typeof file.type === 'undefined') {
                file.type = this.isImage(file) ? 'image' : 'file';
            }

            if (typeof file.comment === 'undefined') {
                file.comment = '';
            }

            file.height = 0;
            file.width = 0;
        },

        showCommentBox(file) {
            this.commentFile = file;
            this.showCommentModal = true;
        },

        showDeleteBox(file, index) {
            ks.confirm(
                this.trans('shared.Slett'),
                file.type === 'file'
                    ? this.trans('shared.Slette filen?')
                    : this.trans('shared.Slette bildet?'),
                null,
                null,
                (choice) => {
                    if (!choice) {
                        return;
                    }

                    this.$emit('delete', file, index);
                },
            );
        },

        showEditBox(file) {
            this.editFile = file;
            this.showEditModal = true;
        },
    },
};
