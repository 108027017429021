// Our own local storage cache implementation. Keys are prefixed with user ID, and can also contain
// project ID or a custom namespace.

import { getFromLocalStorage, setInLocalStorage, removeFromLocalStorage } from './local-storage.js';

/**
 * Get value from the key-value store
 *
 * @param {String} key
 * @param {Number} userId Used to create user key prefix
 * @param {Object} [options]
 * @param {Number} [options.projectId=null] Used to create project key prefix
 * @param {String} [options.namespace=null] Key prefix
 * @param {Any} [options.defaultValue=null] Fallback value if no value exist for the full key
 *
 * @returns {Any} Value stored at the key location
 */
export async function getValue(key, userId, {
    projectId = null,
    namespace = null,
    defaultValue = null,
}) {
    // Set optional prefixes
    key = namespace ? `${namespace}.${key}` : key;
    key = projectId ? `project.${projectId}.${key}` : key;

    // Set mandatory prefix
    key = `user.${userId}.${key}`;

    const parsedValue = getFromLocalStorage(key);

    return parsedValue !== null ? parsedValue : defaultValue;
}

/**
 * Set value in the key-value store
 *
 * @param {String} key
 * @param {Any} value
 * @param {Number} userId Used to create user key prefix
 * @param {Object} [options]
 * @param {Number} [options.projectId=null] Used to create project key prefix
 * @param {String} [options.namespace=null] Key prefix
 * @param {Any} [options.defaultValue=null] Fallback value if no value exist for the full key
 */
export async function setValue(key, value, userId, {
    projectId = null,
    namespace = null,
}) {
    // Set optional prefixes
    key = namespace ? `${namespace}.${key}` : key;
    key = projectId ? `project.${projectId}.${key}` : key;

    // Set mandatory prefix
    key = `user.${userId}.${key}`;

    setInLocalStorage(key, value);
}

/**
 * Remove key from the store
 *
 * @param {String} key
 * @param {Number} userId Used to create user key prefix
 * @param {Object} [options]
 * @param {Number} [options.projectId=null] Used to create project key prefix
 * @param {String} [options.namespace=null] Key prefix
 */
export async function removeKey(key, userId, {
    projectId = null,
    namespace = null,
}) {
    // Set optional prefixes
    key = namespace ? `${namespace}.${key}` : key;
    key = projectId ? `project.${projectId}.${key}` : key;

    // Set mandatory prefix
    key = `user.${userId}.${key}`;

    removeFromLocalStorage(key);
}

export default {
    getValue,
    setValue,
    removeKey,
};
