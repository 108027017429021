<template>
    <span class="required-field">
        <span class="asterisk">&ast;</span>
        <span v-if="showRequiredText" class="text">
            {{ trans('shared.Påkrevd felt') }}
        </span>
    </span>
</template>

<script>
// Simple component for adding asterisk to a required field.
export default {
    name: 'required-field',
    props: {
        showRequiredText: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.required-field {
    font: var(--font-body-xs-regular);
}
.asterisk {
    color: var(--danger);
}
.text {
    color: var(--text-secondary);
}
</style>
