import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faCommentAlt } from '@fortawesome/pro-light-svg-icons/faCommentAlt';
library.add(faDownload, faTrashAlt, faFile, faUpload, faTimes, faChevronLeft, faChevronRight, faCommentAlt);

import kkCanvas from '../kk-canvas/kk-canvas.vue';
import { trans } from '../../mixin/trans';

// @vue/component
export default {
    name: 'kk-gallery',
    components: {
        kkCanvas,
        FontAwesomeIcon,
    },
    mixins: [
        trans,
    ],
    props: {
        /** Each file should have{ src, name, comment } */
        files: {
            type: Array,
            required: true,
            default: () => [],
        },
        /** Used to control the selected file */
        index: {
            type: Number,
            default: 0,
            validator: (index) => {
                return index >= 0;
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showRecentGalleryButton: {
            type: Boolean,
            default: false,
        },
        resizeImage: {
            type: Boolean,
            default: true,
        },
        editModeAvailable: {
            type: Boolean,
            default: true,
        },
        maxVisibleFiles: {
            type: Number,
            default: 40,
        },
        allowDelete: {
            type: Boolean,
            default: true,
        },
        allowUpload: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            rawSelectedIndex: this.index,
            vFilename: (this.files.length === 0) ? '' : this.files[0].name,
            vComment: (this.files.length === 0) ? '' : this.files[0].comment,
            canvasTitle: '',
            commentFocused: false,
            showFilename: true,
            showComment: false,
            visible: false,
            visibleFiles: [],
            vLocations: null,
        };
    },
    computed: {
        hasFiles() {
            return this.files.length > 0;
        },

        noFiles() {
            return this.files.length === 0;
        },

        selectedIndex() {
            if (this.rawSelectedIndex < 0) {
                return 0;
            } else if (this.rawSelectedIndex >= this.files.length) {
                return this.files.length - 1;
            }

            return this.rawSelectedIndex;
        },

        selectedFile() {
            if (this.noFiles) {
                return null;
            }

            return this.files[this.selectedIndex];
        },

        canvasSrc() {
            if (!this.selectedFile) {
                return null;
            }

            if (this.selectedFile instanceof File) {
                const fileObj = this.visibleFiles[this.selectedIndex];

                if (fileObj && 'src' in fileObj) {
                    return fileObj.src;
                }

                return null;
            }

            return `${this.selectedFile.src}${this.resizeImage ? '/2560' : ''}`;
        },

        nameChangeDetected() {
            return this.vFilename !== this.selectedFile.name;
        },

        commentChangeDetected() {
            return this.vComment !== this.selectedFile.comment;
        },

        changeDetected() {
            return this.nameChangeDetected || this.commentChangeDetected;
        },

        indexLeftOfSelected() {
            if ((this.selectedIndex - 1) < 0) {
                return this.files.length - 1;
            }

            return this.selectedIndex - 1;
        },

        indexRightOfSelected() {
            if ((this.selectedIndex + 1) >= this.files.length) {
                return 0;
            }

            return this.selectedIndex + 1;
        },
        locationTypes() {
            return {
                skjema: this.trans('dashboard.Skjema'),
                befaring: this.trans('shared.Befaring'),
                avvik: this.trans('deviation.Avvik'),
                sjekkliste: this.trans('shared.Sjekkliste'),
                ordre: this.trans('shared.Ordre'),
                custom_form: this.trans('dashboard.Egendefinert skjema'),
            };
        },
    },

    watch: {
        files() {
            this.vFilename = '';
            this.vComment = '';
            this.select(this.selectedIndex);
            this.generateVisibleFiles();
        },

        selectedIndex() {
            this.generateVisibleFiles();
        },

        index() {
            this.rawSelectedIndex = this.index;
            this.select(this.selectedIndex);
        },
    },

    mounted() {
        this.select(this.selectedIndex);
        this.generateVisibleFiles();

        // Focus wrapping div on mounted (show), so keyboard bindings are active asap
        if (this.$refs.kkGallery && typeof this.$refs.kkGallery.focus === 'function') {
            this.$refs.kkGallery.focus();
        }
    },

    methods: {
        hide() {
            this.$emit('hide');
        },

        /**
         * Generate files to be shown in the thumbnail slider
         * Also generates correct src to use
         */
        async generateVisibleFiles() {
            if (this.noFiles) {
                this.visibleFiles = [];

                return;
            }

            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            const getFile = async (index) => {
                const file = this.files[index];

                if (file instanceof File) {
                    return { file, src: await toBase64(file) };
                }

                return { file, src: `${file.src}${this.resizeImage ? '/100' : ''}` };
            };

            let visibleFiles = [];

            const initialFile = await getFile(this.selectedIndex);
            visibleFiles.push({
                index: this.selectedIndex,
                ...initialFile,
                visible: true,
            });

            let leftIndex = this.selectedIndex;
            let rightIndex = this.selectedIndex;

            let filesLeftToShow = true;

            while (filesLeftToShow) {
                // To the left ...
                if (visibleFiles.length >= (this.files.length * 2 - 1) || visibleFiles.length >= this.maxVisibleFiles) {
                    filesLeftToShow = false;
                }

                leftIndex -= 1;
                const leftFile = await getFile((leftIndex + this.files.length) % this.files.length);
                visibleFiles.unshift({
                    index: leftIndex,
                    ...leftFile,
                    visible: leftIndex >= 0,
                });

                // To the right ...
                if (visibleFiles.length >= (this.files.length * 2 - 1) || visibleFiles.length >= this.maxVisibleFiles) {
                    filesLeftToShow = false;
                }

                rightIndex += 1;
                const rightFile = await getFile(rightIndex % this.files.length);
                visibleFiles.push({
                    index: rightIndex,
                    ...rightFile,
                    visible: rightIndex < this.files.length,
                });
            }

            this.visibleFiles = visibleFiles;
        },

        isSelectedFile(fileIndex) {
            return fileIndex === this.selectedIndex;
        },

        select(newIndex) {
            if (this.noFiles) {
                this.reset();

                return;
            }

            this.rawSelectedIndex = newIndex;

            // Do not show comment and filename of not uploaded files
            if (this.selectedFile instanceof File) {
                this.showFilename = false;
                this.showComment = false;

                return;
            }

            this.vFilename = this.selectedFile.name;
            this.vComment = this.selectedFile.comment;
            this.vLocations = this.selectedFile?.locations?.length ? this.selectedFile.locations : null;
            this.showComment = this.vComment !== '';

            this.$refs.comment.innerHTML = this.vComment;
        },
        reset() {
            this.rawSelectedIndex = 0;
            this.vFilename = '';
            this.vComment = '';
            this.vLocations = null;
            this.showComment = false;
            this.$refs.comment.innerHTML = '';
        },

        getFileTooltip(file) {
            if (file.file.comment) {
                return `${file.file.name}\n\n${file.file.comment}`;
            }

            return file.file.name;
        },

        //
        // Emitters
        //
        emitOpenRecentGallery() {
            this.$emit('open-recent-gallery');
        },
        emitRename(name) {
            if (this.noFiles) {
                return;
            }
            this.$emit('rename', {
                index: this.selectedIndex,
                id: this.selectedFile.id,
                name,
            });
        },

        emitComment(comment) {
            if (this.noFiles) {
                return;
            }
            this.$emit('comment', {
                index: this.selectedIndex,
                id: this.selectedFile.id,
                comment,
            });
        },

        emitDelete() {
            if (this.noFiles) {
                return;
            }
            this.$emit('delete', {
                index: this.selectedIndex,
                id: this.selectedFile.id,
            });
        },

        emitUpload(target) {
            this.$emit('upload', [...target.files]);
        },

        emitClose() {
            this.$emit('close');
        },
        //
        // Event handlers
        //
        onChangeUpload(event) {
            if (event.target.files.length === 0) {
                return;
            }
            this.emitUpload(event.target);
        },

        onBlurFilename() {
            if (this.nameChangeDetected === false || this.noFiles) {
                return;
            }
            this.emitRename(this.vFilename);
        },

        onFocusComment() {
            this.commentFocused = true;
        },

        onBlurComment({ target }) {
            this.commentFocused = false;

            this.vComment = target.innerText;

            if (this.commentChangeDetected === false || this.noFiles) {
                return;
            }
            this.emitComment(this.vComment);
        },

        onKeydownComment(e) {
            this.vComment = e.target.innerText;
            e.stopPropagation();
        },

        onClickThumbnail(clickedIndex) {
            this.select(clickedIndex);
        },

        onKeyLeft(e) {
            e.preventDefault();
            this.select(this.indexLeftOfSelected);
        },

        onKeyRight(e) {
            e.preventDefault();
            this.select(this.indexRightOfSelected);
        },

        onClickFilename() {
            if (this.selectedFile instanceof File) {
                return;
            }

            this.showFilename = !this.showFilename;
        },

        onClickComment() {
            if (this.selectedFile instanceof File) {
                return;
            }

            this.showComment = !this.showComment;
            this.$refs.comment.focus();
        },

        onClickBackToStart() {
            this.select(0);
        },
    },
};
