export const namespace = 'inspection';
export const settings = 'inspection/settings';

import { createNamespacedHelpers } from 'vuex';
import axios from 'axios';
export const { mapGetters, mapActions } = createNamespacedHelpers(namespace);

const getters = {
    settings: state => state.settings,
};

const SETTINGS_SET = 'SETTINGS_SET';
const mutations = {
    [SETTINGS_SET]: (state, settings) => {
        state.settings = settings;
    },
};

export const getSettings = 'getSettings';
export const updateSettings = 'updateSettings';

const actions = {
    [getSettings]: async ({ commit }, payload = null) => {
        // If we provide a default payload object we just commit this
        if (payload !== null && typeof payload.constructor === 'function') {
            commit(SETTINGS_SET, payload);

            return;
        }

        const settings = (await axios.get('/api/inspections/settings')).data.data;
        commit(SETTINGS_SET, settings);
    },
    [updateSettings]: async ({ commit }, settings) => {
        const newSettings = (await axios.put('/api/inspections/settings', settings)).data.data;
        commit(SETTINGS_SET, newSettings);
    },
};

const state = {
    settings: {
        column_names: {
            building: '',
            floor: '',
            part: '',
            room: '',
        },
        defaults: {},
        visible_columns: {
            building: false,
            floor: false,
            part: false,
            room: false,
        },
        visible_print_columns: {
            number: true,
            building: true,
            floor: true,
            part: true,
            room: true,
            description: true,
            responsible: true,
            dates: true,
            comment: true,
        },
        printImageSize: 150,
        printOrientation: 'landscape',
    },
};

export const inspection = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
