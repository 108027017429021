import kkButton from '../kk-button/kk-button.vue';
import svgIcon from '../svg-icon/svg-icon.vue';
import { trans } from '../../mixin/trans.js';
import ClickOutside from 'vue-click-outside';

// @vue/component
export default {
    name: 'kk-modal',

    directives: {
        ClickOutside,
    },

    components: {
        kkButton,
        svgIcon,
    },

    mixins: [
        trans,
    ],

    props: {
        /**
         * Show close button
         * @default true
         */
        closeButton: {
            type: Boolean,
            default: true,
        },

        /**
         * Show close button on the right side
         * @default false
         */
        closeOnRight: {
            type: Boolean,
            default: false,
        },

        /**
         * Include footer
         * @default true
         */
        footer: {
            type: Boolean,
            default: true,
        },

        /**
         * Include header
         * @default true
         */
        header: {
            type: Boolean,
            default: true,
        },

        /**
         * Scroll lock the body. Does not work in iOS for now.
         * @default true
         */
        scrollLock: {
            type: Boolean,
            default: true,
        },

        /**
         * Fullscreen for mobile
         * @default false
         */
        fullscreen: {
            type: Boolean,
            default: false,
        },

        /**
         * Tooltip-like style
         * @default false
         */
        tooltip: {
            type: Boolean,
            default: false,
        },

        /**
         * Element that triggers modal, required for positioning tooltip.
         * @default null
         */
        trigger: {
            type: HTMLElement,
            default: null,
        },

    },

    data: () => ({
        isOpen: false,
        arrowPosition: {},
        modalPosition: {},
        arrow: '',
    }),

    created() {
        if (this.scrollLock && !this.tooltip) {
            this.setScrollLock(true);
        }
    },

    mounted() {
        if (this.tooltip && this.trigger) {
            window.addEventListener('resize', this.calculateModalPosition);
            window.addEventListener('scroll', this.calculateModalPosition);
            this.calculateModalPosition();
        }
    },

    beforeDestroy() {
        if (this.scrollLock && !this.tooltip) {
            this.setScrollLock(false);
        }

        if (this.tooltip && this.trigger) {
            window.removeEventListener('resize', this.calculateModalPosition);
            window.removeEventListener('scroll', this.calculateModalPosition);
        }
    },

    methods: {
        clickOutside(evt) {
            if (evt.target === this.$el) {
                // Allow for custom-event @click-outside, but default to emitting the @cancel event
                // if @click-outside doesn't have any listeners.
                if (this.$listeners['click-outside']) {
                    this.$emit('click-outside');
                } else {
                    this.$emit('cancel');
                }
            }
        },

        setScrollLock(on) {
            if (!on) {
                document.body.style.overflow = '';

                return;
            }

            document.body.style.overflow = 'hidden';
        },
        calculateModalPosition() {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const triggerEl = this.trigger.getBoundingClientRect();
            const modalEl = this.$refs.modal.getBoundingClientRect();
            const margin = 25;

            let modalY = null;
            let modalX = null;
            let arrowX = null;
            let arrowY = null;
            // Arrow width is 30px, height is 20px;

            // Calculate modal position on x axis
            if (windowWidth - triggerEl.right + triggerEl.width / 2 < 15 + margin
                || triggerEl.left + triggerEl.width / 2 < 15 + margin) {
                // If trigger is too close to the window right or left edge,
                // display the modal in the center of the window, like default kk-modal.
                this.arrowPosition = {};
                this.modalPosition = {};

                return;
            } else if ((windowWidth - triggerEl.left - triggerEl.width / 2) > modalEl.width / 2
                && (triggerEl.left + triggerEl.width / 2) > modalEl.width / 2) {
                // If there is enough space, align the center of the modal with the center of the trigger
                modalX = triggerEl.left + (triggerEl.width / 2) - (modalEl.width / 2);
            } else if (triggerEl.left > windowWidth - triggerEl.right) {
                // If the trigger is closer to the right edge of window, right align
                modalX = windowWidth - modalEl.width - margin;
            } else {
                // If the trigger is closer to the left edge of window, left align
                modalX = margin;
            }

            const height = 20 + modalEl.height;

            // Calculate the modal position on y axis & arrow position
            if (height < windowHeight - triggerEl.bottom) {
                // Position the modal below the trigger
                modalY = triggerEl.bottom + 19; // -1px to hide modal top border

                this.arrow = 'up';
                arrowX = triggerEl.left + (triggerEl.width / 2) - 15;
                arrowY = triggerEl.bottom;
            } else if (height < triggerEl.top) {
                // Position the modal above the trigger
                modalY = triggerEl.top - height;

                this.arrow = 'down';
                arrowX = triggerEl.left + (triggerEl.width / 2) - 15;
                arrowY = triggerEl.top - 21; // +1px to hide modal bottom border
            } else {
                // If there is not enough space above or below trigger,
                // the modal is displayed in the center of the window, like default kk-modal.
                this.arrowPosition = {};
                this.modalPosition = {};

                return;
            }

            if (modalY && modalX) {
                this.arrowPosition = {
                    display: 'block',
                    transform: `translate(${arrowX}px, ${arrowY}px)`,
                };

                this.modalPosition = {
                    top: 'auto',
                    left: 'auto',
                    transform: `translate(${modalX}px, ${modalY}px)`,
                };
            }
        },
    },
};
