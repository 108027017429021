export const mousestop = {
    inserted: (el) => {
        el.addEventListener('mousedown', (e) => {
            e.stopPropagation();
        });
        el.addEventListener('mousemove', (e) => {
            e.stopPropagation();
        });
        el.addEventListener('mouseup', (e) => {
            e.stopPropagation();
        });
        el.addEventListener('mouseleave', (e) => {
            e.stopPropagation();
        });
        el.addEventListener('mouseenter', (e) => {
            e.stopPropagation();
        });
    },
};

export const touchstop = {
    inserted: (el) => {
        el.addEventListener('touchstart', (e) => {
            e.stopPropagation();
        });
        el.addEventListener('touchmove', (e) => {
            e.stopPropagation();
        });
        el.addEventListener('touchcancel', (e) => {
            e.stopPropagation();
        });
        el.addEventListener('touchend', (e) => {
            e.stopPropagation();
        });
    },
};
