/**
 * Genereate filename from date
 * @see https://stackoverflow.com/questions/5416920/timestamp-to-human-readable-format/5416970#5416970
 *
 * @param {Date} date to be encoded into filename string
 * @param {String} extension defaults to 'jpeg'
 *
 * @return {String} example 2018-12-23--18-04-56.jpg
 */
export function filenameFromDate(date, { extension = 'jpeg' } = {}) {
    const maybePrependZero = (number) => {
        const numStr = `${number}`;
        const prependStr = number < 10 ? '0' : '';

        return prependStr + numStr;
    };

    return `\
${date.getFullYear()}-\
${maybePrependZero(date.getMonth() + 1)}-\
${maybePrependZero(date.getDate())}--\
${maybePrependZero(date.getHours())}-\
${maybePrependZero(date.getMinutes())}-\
${maybePrependZero(date.getSeconds())}.${extension}`;
}
